import { getOrganization } from '@/app/common';
import { tocoMessage } from '@/components/message';
import ModalForm from '@/components/modalForm';
import Upload from '@/components/Upload';
import services from '@/services';
import { getOssPolicy } from '@/services/utils';
import {
  Button,
  Form,
  Input,
  Table,
  TableOnFetchFunc,
  TableProps,
} from '@df/toco-ui';
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type FieldType = {
  logo?: string;
  name?: string;
};

type AddFormPropsType = {
  triggerNode?: any;
};

export const AddForm = (props: AddFormPropsType) => {
  const { triggerNode } = props;
  const formRef = useRef<any>();
  const dispatch = useDispatch();

  const handleSubmit = async (v) => {
    if (v?.name) {
      const data = {
        ...v,
        logo: v?.logo?.[0]?.url ?? '',
      };
      const res =
        await services.OrganizationController_createOrganization_fba0fb({
          createOrganizationBto: data,
          extApiParam: {},
        });
      if (res?.code === 200) {
        tocoMessage(res);
        dispatch(
          getOrganization(true, {
            ...res.data,
            organizationId: res.data?.id,
          }) as any,
        );
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalForm
      triggerNode={triggerNode ? triggerNode : <Button>新增</Button>}
      title="新增组织"
      formHandle={formRef}
      onSubmit={handleSubmit}
    >
      <Form
        ref={formRef}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="组织名称"
          name="name"
          required
          rules={[
            { required: true, message: '请输入组织名称' },
            { max: 30, message: '组织名称最大长度不超过30个字' },
          ]}
        >
          <Input maxLength={30} placeholder="请输入组织名称" />
        </Form.Item>
        <Form.Item<FieldType> label="组织Logo" name="logo">
          <Upload
            getUploadOssParams={getOssPolicy}
            uploadType="picture"
            uploadPaste={true}
            uploadParams={{
              maxCount: 1,
              accept: 'image/*',
              onPreview: () => false,
              className: 'toco-upload-preview',
            }}
          />
        </Form.Item>
      </Form>
    </ModalForm>
  );
};

const SearchForm = (props: any) => {
  const { onChange, loading } = props;

  return (
    <div>
      {/* <Form
        autoComplete="off"
        onFinish={onChange}
        onReset={() => onChange(undefined)}
        layout="inline"
      >
        <Form.Item label="是否是女生" name="isFemale">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="text" htmlType="reset">
            Reset
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form> */}
      <div
        style={{
          margin: '12px 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <AddForm />
      </div>
    </div>
  );
};

const Index = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<any>();

  const handleDetail = (data) => {
    const url = `/organization/${data?.id}`;
    navigate(url);
  };

  const columns: TableProps['columns'] = [
    {
      title: '组织名称',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => `${record.name}`,
    },
    {
      title: 'logo',
      dataIndex: 'logo',
      key: 'logo',
    },
    {
      title: '操作',
      dataIndex: 'logo',
      key: 'logo',
      render: (_, record) => (
        <div>
          <Button
            onClick={() => {
              handleDetail(record);
            }}
          >
            详情
          </Button>
        </div>
      ),
    },
  ];

  const onFetch: TableOnFetchFunc<FieldType, Record<string, any>> = useCallback(
    async (params) => {
      const res =
        await services.OrganizationController_queryOrganizationByUserIdPaged_5b568d(
          {
            qto: {
              size: params?.pagination?.pageSize ?? 10,
              from: params?.pagination?.current
                ? params?.pagination?.current - 1
                : 0,
            },
          },
        );
      const d: any = res.data ?? {};
      return { data: d?.result, total: d.count };
    },
    [],
  );

  const handleSearch = (v) => {
    setSearchValue(v);
  };

  const getList = async () => {
    // const res = await
    console.log();
  };

  const tableRef = useRef<any>();

  return (
    <div>
      <Table
        ref={tableRef}
        columns={columns}
        onFetch={onFetch}
        searchComponent={SearchForm}
        pagination={{
          pageSize: 10,
        }}
      />
    </div>
  );
};

export default Index;
