import { selectCommon, setCurrentOrg } from '@/app/common';
import Icon from '@/components/icon';
import { AddForm } from '@/modules/organization';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Image, Input, Popover } from '@df/toco-ui';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';

type ModuleInfoType = {
  name?: string;
  isPerson?: boolean;
  logo?: string;
};

type ChoosePropsType = {
  children?: React.ReactNode;
};

export const renderRole = (v) => {
  let value = '';
  switch (v) {
    case 'SUPER_ADMIN':
      value = '超级管理员';
      break;
    case 'ADMIN':
      value = '管理员';
      break;
    case 'PARTICIPATE':
      value = '组织成员';
      break;
    case 'TEMPORARY':
      value = '临时成员';
      break;
  }
  return value;
};

const Index = (props: ChoosePropsType) => {
  const { children } = props;
  const { currentOrg, organization, userInfo, rootOrg } =
    useSelector(selectCommon);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState<ModuleInfoType | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const navigate = useNavigate();

  const handleSetCurrentOrg = useCallback(
    (v) => {
      setCurrent(v);
      setTimeout(() => {
        dispatch(setCurrentOrg(v));
      }, 0);
    },
    [dispatch],
  );

  const handleToggleOrg = useCallback(
    (data) => {
      handleSetCurrentOrg(data);
      navigate(`/organization/${data?.organizationId}/home`);
      setOpen(false);
      setSearch(undefined);
    },
    [handleSetCurrentOrg, navigate],
  );

  const handleClickAdd = () => {
    setOpen(false);
    setSearch(undefined);
  };

  const handelChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (currentOrg) {
      setCurrent(currentOrg);
    }
  }, [currentOrg]);

  const renderContent = useCallback(() => {
    const target = [...(organization ?? [])].sort((a, b) => {
      if (a.regionRoot) return -1;
      if (b.regionRoot) return 1;
      const flag = {
        SUPER_ADMIN: 3,
        ADMIN: 2,
        PARTICIPATE: 1,
      };
      return flag[b.role ?? 'PARTICIPATE'] - flag[a.role ?? 'PARTICIPATE'];
    });
    const searchTarget = target.filter(
      (item) => !search || (item.name && item.name?.indexOf(search) > -1),
    );

    return (
      <div className={styles?.['module-container']}>
        {target?.length ? (
          <div className={styles?.['module-search']}>
            <Input
              value={search}
              onChange={handelChange}
              prefix={<SearchOutlined />}
              placeholder="搜索组织"
            />
          </div>
        ) : null}
        <div className={styles?.['module-main-container']}>
          {searchTarget?.map((item) => {
            if (item.name && search && item.name?.indexOf(search) < 0)
              return null;
            return (
              <div
                key={item?.id}
                className={classNames(styles?.['module-item'], {
                  [styles?.['module-item-active']]:
                    item?.organizationId === currentOrg?.organizationId,
                })}
                onClick={() => {
                  handleToggleOrg(item);
                }}
              >
                {item?.logo ? (
                  <span>
                    <Image
                      src={item?.logo}
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      preview={false}
                    />
                  </span>
                ) : (
                  <span
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  ></span>
                )}

                <div className={styles?.['module-item-block']}>
                  <span className={styles?.['module-item-name']}>
                    {item?.name}
                  </span>
                  <span className={styles?.['module-item-role']}>
                    {renderRole(item?.role)}
                  </span>
                </div>
              </div>
            );
          })}
          {!searchTarget.length ? (
            <div className={styles?.['module-empty']}>暂无数据</div>
          ) : null}
        </div>
        {rootOrg?.role === 'SUPER_ADMIN' || rootOrg?.role === 'ADMIN' ? (
          <div className={styles?.['module-item-add']}>
            <AddForm
              triggerNode={
                <div onClick={handleClickAdd}>
                  <PlusOutlined />
                  <span>新建组织</span>
                </div>
              }
            />
          </div>
        ) : null}
      </div>
    );
  }, [currentOrg, handleToggleOrg, organization, search, rootOrg]);

  return (
    <div className={styles?.['change-module']}>
      <Popover
        placement="bottom"
        title=""
        content={renderContent()}
        trigger={['click']}
        open={open}
        onOpenChange={(v) => {
          setOpen(v);
          setSearch(undefined);
        }}
        overlayClassName={styles?.['change-module-popover']}
        arrow={false}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          {children}
          {!open ? <Icon type="toco-m-down" /> : <Icon type="toco-m-right" />}
        </div>
      </Popover>
    </div>
  );
};

export default Index;
