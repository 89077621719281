import { createServices } from '@vs/service';
export default createServices({
  VsProjectMainController_addUserToProject_15a118: {
    method: 'post',
    url: '/api/vs-project-main/add-user-to-project',
    parameterFomatter: (data?: {
      addUserToProjectBto: AddUserToProjectBtoVsProjectMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProjectVoVsProjectMainEntranceWebVo) =>
      data,
  },
  VsProjectMainController_completeInvite_30ba77: {
    method: 'post',
    url: '/api/vs-project-main/complete-invite',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_createProject_10ac58: {
    method: 'post',
    url: '/api/vs-project-main/create-project',
    parameterFomatter: (data?: {
      createProjectBto: CreateProjectBtoVsProjectMainServiceBto;
      extApiParam: {
        dbType: string;
        organizationId: number;
      };
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VsProjectMainController_generateInviteCode_4d35dd: {
    method: 'post',
    url: '/api/vs-project-main/generate-invite-code',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  VsProjectMainController_getInviteInfo_05abe9: {
    method: 'post',
    url: '/api/vs-project-main/get-invite-info',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectInviteInfoVoVsProjectMainEntranceWebVo,
    ) => data,
  },
  VsProjectMainController_getProjectById_bf656a: {
    method: 'post',
    url: '/api/vs-project-main/get-project-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ProjectVoVsProjectMainEntranceWebVo) =>
      data,
  },
  VsProjectMainController_queryProjectByOrganizationPaged_4494f9: {
    method: 'post',
    url: '/api/vs-project-main/query-project-by-organization-paged',
    parameterFomatter: (data?: {
      qto: ProjectByOrganizationQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectVoVsProjectMainEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VsProjectMainController_queryProjectTagByOrganization_e9ba83: {
    method: 'post',
    url: '/api/vs-project-main/query-project-tag-by-organization',
    parameterFomatter: (data?: {
      qto: ProjectTagByOrganizationQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectTagVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  VsProjectMainController_queryProjectUuidNameByUser_1fc834: {
    method: 'post',
    url: '/api/vs-project-main/query-project-uuid-name-by-user',
    parameterFomatter: (data?: {
      qto: ProjectUuidNameByUserQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectUuidNameVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  VsProjectMainController_queryProjectWithOrganizationByUser_510181: {
    method: 'post',
    url: '/api/vs-project-main/query-project-with-organization-by-user',
    parameterFomatter: (data?: {
      qto: ProjectWithOrganizationByUserQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectWithOrganizationVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  VsProjectMainController_queryUserByProjectPaged_75638d: {
    method: 'post',
    url: '/api/vs-project-main/query-user-by-project-paged',
    parameterFomatter: (data?: {
      qto: UserByProjectQtoVsProjectMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectVsUserVoVsProjectMainEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VsProjectMainController_removeUserFromProject_7499f8: {
    method: 'post',
    url: '/api/vs-project-main/remove-user-from-project',
    parameterFomatter: (data?: {
      removeUserFromProjectBto: RemoveUserFromProjectBtoVsProjectMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProjectVoVsProjectMainEntranceWebVo) =>
      data,
  },
  VsProjectMainController_transferProject_0865c9: {
    method: 'post',
    url: '/api/vs-project-main/transfer-project',
    parameterFomatter: (data?: { projectId: number; toUserId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_updateProject_4c802d: {
    method: 'post',
    url: '/api/vs-project-main/update-project',
    parameterFomatter: (data?: {
      updateProjectBto: UpdateProjectBtoVsProjectMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VsProjectMainController_visitProject_5cd1a9: {
    method: 'post',
    url: '/api/vs-project-main/visit-project',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  VsProjectMainController_updateProjectInitStatus_30f454: {
    method: 'post',
    url: '/api/vs-project-main/update-project-init-status',
    parameterFomatter: (data?: {
      updateProjectInitStatusBto: UpdateProjectInitStatusBtoVsProjectMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ProjectVoVsProjectMainEntranceWebVo) =>
      data,
  },
  UserAccountController_getAccountById_8ab15e: {
    method: 'post',
    url: '/api/user-account/get-account-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_getAccountInfo_11ced6: {
    method: 'post',
    url: '/api/user-account/get-account-info',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_getOssPolicy_eaa6a4: {
    method: 'post',
    url: '/api/user-account/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_logout_7c3e9b: {
    method: 'post',
    url: '/api/user-account/logout',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserAccountController_queryAccountVoByAccountByOrganizationPaged_d4e3ce: {
    method: 'post',
    url: '/api/user-account/query-query-account-vo-by-account-by-organization-paged-by-{}{}',
    parameterFomatter: (data?: {
      qto: AccountByOrganizationQtoUserAccountPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: AccountVoUserAccountEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserAccountController_updateAccount_6e3982: {
    method: 'post',
    url: '/api/user-account/update-account',
    parameterFomatter: (data?: {
      btoParam: UpdateAccountBtoUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_updateAccountStatus_0de17c: {
    method: 'post',
    url: '/api/user-account/update-account-status',
    parameterFomatter: (data?: {
      btoParam: UpdateAccountStatusBtoUserAccountServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserLoginController_phoneAndVerifyLogin_825683: {
    method: 'post',
    url: '/api/user-account/phone-and-verify-login',
    parameterFomatter: (data?: { phone: string; verify: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserLoginController_sendVerifyCode_963063: {
    method: 'post',
    url: '/api/user-account/send-verify-code',
    parameterFomatter: (data?: { phone: string; model: string }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  UserLoginController_userPasswordLogin_07931d: {
    method: 'post',
    url: '/api/user-account/user-password-login',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (_, __, data?: AccountVoUserAccountEntranceWebVo) =>
      data,
  },
  UserAccountController_queryCertificationBaseVoByFromPaged_9f2343: {
    method: 'post',
    url: '/api/user-account/query-certification-base-vo-by-from-paged',
    parameterFomatter: (data?: { qto: FromQtoUserAccountPersistQto }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CertificationBaseVoUserAccountEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VsProjectMainController_hasProjectPermission_c5e57d: {
    method: 'post',
    url: '/api/project-main/has-project-permission',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_isProjectOwner_4d3c68: {
    method: 'post',
    url: '/api/project-main/is-project-owner',
    parameterFomatter: (data?: { projectId: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VsProjectMainController_getProjectSimpleByUser_655320: {
    method: 'post',
    url: '/api/project/list/by/user',
    parameterFomatter: (data?: { userId: number; username: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectUuidNameVoVsProjectMainEntranceWebVo[],
    ) => data,
  },
  OrganizationController_transferOrganization_ceaf1c: {
    method: 'post',
    url: '/api/organization-entrance-web/transfer-organization',
    parameterFomatter: (data?: { organizationId: number; toUserId: number }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_completeInvite_8be634: {
    method: 'post',
    url: '/api/organization/complete-invite',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_createOrganization_fba0fb: {
    method: 'post',
    url: '/api/organization/create-organization',
    parameterFomatter: (data?: {
      createOrganizationBto: CreateOrganizationBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_generateOrganizationInviteCode_cf5498: {
    method: 'post',
    url: '/api/organization/generate-invite-code',
    parameterFomatter: (data?: {
      organizationId: number;
      role: OrganizationRoleEnum;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OrganizationController_getAllRoleTag_243b69: {
    method: 'post',
    url: '/api/organization/get-all-role-tag',
    parameterFomatter: (data?: { qto: AllRoleTagQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationRoleTagVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_getInviteInfo_4dde0a: {
    method: 'post',
    url: '/api/organization/get-invite-info',
    parameterFomatter: (data?: { inviteCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationInviteInfoVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_getOrganizationVoById_d66224: {
    method: 'post',
    url: '/api/organization/get-organization-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_queryOrganizationByUserIdPaged_5b568d: {
    method: 'post',
    url: '/api/organization/query-organization-by-user-id-paged',
    parameterFomatter: (data?: {
      qto: OrganizationByUserIdQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrganizationVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrganizationController_queryOrganizationUserInfoByUser_ea4149: {
    method: 'post',
    url: '/api/organization/query-organization-user-info-by-user',
    parameterFomatter: (data?: {
      qto: OrganizationUserInfoByUserQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVsUserInfoVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_queryOrganizationVsProjectByUser_86868c: {
    method: 'post',
    url: '/api/organization/query-organization-vs-project-by-user',
    parameterFomatter: (data?: {
      qto: OrganizationVsProjectByUserQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVsProjectVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_queryOrganizationVsProjectVoByOrganizationProjectListPaged_72ad30:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-project-vo-by-organization-project-list-paged',
      parameterFomatter: (data?: {
        qto: OrganizationProjectListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsProjectVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryOrganizationVsProjectVoByOrganizationProjectListWaterfall_f3a08f:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-project-vo-by-organization-project-list-waterfall',
      parameterFomatter: (data?: {
        qto: OrganizationProjectListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsProjectVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryOrganizationVsUserVoByOrganizationUserListPaged_21b390:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-user-vo-by-organization-user-list-paged',
      parameterFomatter: (data?: {
        qto: OrganizationUserListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsUserVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryOrganizationVsUserVoByOrganizationUserListWaterfall_9fb679:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-user-vo-by-organization-user-list-waterfall',
      parameterFomatter: (data?: {
        qto: OrganizationUserListQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrganizationVsUserVoOrganizationEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OrganizationController_queryProjectTransferRecordByUserPaged_230c4e: {
    method: 'post',
    url: '/api/organization/query-project-transfer-record-by-user-paged',
    parameterFomatter: (data?: {
      qto: ProjectTransferRecordByUserQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectTransferRecordVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrganizationController_transferProjectApply_9f315b: {
    method: 'post',
    url: '/api/organization/transfer-project-apply',
    parameterFomatter: (data?: {
      projectId: number;
      toUserId: number;
      toOrganizationId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_transferProjectConfirm_6b3530: {
    method: 'post',
    url: '/api/organization/transfer-project-confirm',
    parameterFomatter: (data?: { transferRecordId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_updateOrganization_912329: {
    method: 'post',
    url: '/api/organization/update-organization',
    parameterFomatter: (data?: {
      updateOrganizationBto: UpdateOrganizationBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_updateUserInOrganization_abfea2: {
    method: 'post',
    url: '/api/organization/update-user-in-organization',
    parameterFomatter: (data?: {
      organizationVsUserBto: OrganizationVsUserBtoOrganizationServiceBtoCreateorganizationbto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrganizationController_getOrganizationOfRegion_efc270: {
    method: 'post',
    url: '/api/organization/get-organization-of-region',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_getOrganizationRole_ee9a45: {
    method: 'post',
    url: '/api/organization/role',
    parameterFomatter: (data?: { organizationId: number }) => data,
    responseFormatter: (_, __, data?: OrganizationRoleEnum) => data,
  },
  OrganizationController_regionAdminAddOrganization_04172e: {
    method: 'post',
    url: '/api/organization/region-admin-add-organization',
    parameterFomatter: (data?: {
      organizationId: number;
      role: OrganizationRoleEnum;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_addUserToOrganization_e1d8d9: {
    method: 'post',
    url: '/api/organization/add-user-to-organization',
    parameterFomatter: (data?: {
      addUserToOrganizationBto: AddUserToOrganizationBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_removeUserFromOrganization_d1ab24: {
    method: 'post',
    url: '/api/organization/remove-user-from-organization',
    parameterFomatter: (data?: {
      removeUserFromOrganizationBto: RemoveUserFromOrganizationBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_completeTransferProject_7a82bb: {
    method: 'post',
    url: '/api/organization/complete-transfer-project',
    parameterFomatter: (data?: { transferCode: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_generateProjectTransferCode_92d29f: {
    method: 'post',
    url: '/api/organization/generate-transfer-project-code',
    parameterFomatter: (data?: {
      projectId: number;
      toOrganizationId: number;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OrganizationController_getTransferProjectInfo_1b4fa5: {
    method: 'post',
    url: '/api/organization/get-transfer-project-info',
    parameterFomatter: (data?: { transferCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectTransferVoOrganizationEntranceWebVo,
    ) => data,
  },
  OrganizationController_queryOrganizationDupVoByOrganizationDup_76be04: {
    method: 'post',
    url: '/api/organization/query-organization-dup-vo-by-organization-dup',
    parameterFomatter: (data?: {
      qto: OrganizationDupQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationDupVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_queryOrganizationVsProjectVoByOrganizationVsProjectByUser_1ae52f:
    {
      method: 'post',
      url: '/api/organization/query-organization-vs-project-vo-by-organization-vs-project-by-user',
      parameterFomatter: (data?: {
        qto: OrganizationVsProjectByUserQtoOrganizationPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: OrganizationVsProjectVoOrganizationEntranceWebVo[],
      ) => data,
    },
  OrganizationController_queryOrganizationWithProjectUser_95f060: {
    method: 'post',
    url: '/api/organization/queryOrganizationWithProjectUser',
    parameterFomatter: (data?: {
      qto: QueryOrganizationWithProjectUserQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrganizationWithProjectUserVoOrganizationEntranceWebVo[],
    ) => data,
  },
  RegionController_queryAccountByOrganizationRole_40b940: {
    method: 'post',
    url: '/api/region/query-account-by-organization-role',
    parameterFomatter: (data?: {
      qto: AccountByOrganizationRoleQtoRegionPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: AccountInRegionVoRegionEntranceWebVo[]) =>
      data,
  },
  RegionController_queryAccountByRegionPaged_9a4bfe: {
    method: 'post',
    url: '/api/region/query-account-by-region-paged',
    parameterFomatter: (data?: { qto: AccountByRegionQtoRegionPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: AccountInRegionVoRegionEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  RegionController_updateRegion_9a50f3: {
    method: 'post',
    url: '/api/region/update-region',
    parameterFomatter: (data?: {
      updateRegionBto: UpdateRegionBtoRegionServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  RegionController_getRegionWithTenantEnterpriseById_3b372d: {
    method: 'post',
    url: '/api/region/get-region-with-tenant-enterprise-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: RegionWithTenantEnterpriseVoOrganizationEntranceWebVoOrganizationvo,
    ) => data,
  },
  VsProjectResourceController_createProjectResource_5309a2: {
    method: 'post',
    url: '/api/vs-project-resource/create-project-resource',
    parameterFomatter: (data?: {
      createProjectResourceBto: CreateProjectResourceBtoVsProjectResourceServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectResourceVoVsProjectResourceEntranceWebVo,
    ) => data,
  },
  VsProjectResourceController_createResourceItem_295b55: {
    method: 'post',
    url: '/api/vs-project-resource/create-resource-item',
    parameterFomatter: (data?: {
      projectResourceItemBto: ProjectResourceItemBtoVsProjectResourceServiceBtoCreateresourceitembto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VsProjectResourceController_deleteProjectResource_661699: {
    method: 'post',
    url: '/api/vs-project-resource/delete-project-resource',
    parameterFomatter: (data?: {
      deleteProjectResourceBto: DeleteProjectResourceBtoVsProjectResourceServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectResourceVoVsProjectResourceEntranceWebVo,
    ) => data,
  },
  VsProjectResourceController_deleteResourceItem_e08db5: {
    method: 'post',
    url: '/api/vs-project-resource/delete-resource-item',
    parameterFomatter: (data?: {
      projectResourceItemBto: ProjectResourceItemBtoVsProjectResourceServiceBtoCreateresourceitembto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VsProjectResourceController_queryResourceByProject_504460: {
    method: 'post',
    url: '/api/vs-project-resource/query-resource-by-project',
    parameterFomatter: (data?: {
      qto: QueryResourceByProjectQtoVsProjectResourcePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectResourceVoVsProjectResourceEntranceWebVo[],
    ) => data,
  },
  VsProjectResourceController_updateProjectResource_4092c9: {
    method: 'post',
    url: '/api/vs-project-resource/update-project-resource',
    parameterFomatter: (data?: {
      updateProjectResourceBto: UpdateProjectResourceBtoVsProjectResourceServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ProjectResourceVoVsProjectResourceEntranceWebVo,
    ) => data,
  },
  VsProjectResourceController_updateResourceItem_6c940f: {
    method: 'post',
    url: '/api/vs-project-resource/update-resource-item',
    parameterFomatter: (data?: {
      projectResourceItemBto: ProjectResourceItemBtoVsProjectResourceServiceBtoCreateresourceitembto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
