import { theme, ThemeConfig } from '@df/toco-ui';
import { SeedToken } from 'antd/es/theme/interface/seeds';
import { MapToken } from './index';

const { compactAlgorithm, defaultAlgorithm } = theme;

function hexToRgb(hex) {
  // 移除可能存在的'#'符号，并确保是6位长度
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (h) {
        return h + h;
      })
      .join('');
  }

  // 将十六进制转换为RGB值
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

const customAlgorithm: (token: SeedToken) => MapToken = (token) => {
  const calcTokens = defaultAlgorithm(token);

  const controlDefaultHeight = 32;

  return {
    ...calcTokens,
    controlDefaultHeight,
    controlHeight: controlDefaultHeight,
    colorBgEdit: '#FAFAFA', // 编辑页面底色
    colorPrimaryValue: hexToRgb(token.colorPrimary),
    colorBorderTertiary: '#e6e6e6',
    colorBorderCustom: '#E6E6E6', // 边框颜色自定义
    colorBgSelected: '#F2F2F2', // 选中背景色
    colorBgFloatBar: '#FFFFFF', // 悬浮工具栏背景色
    colorBgCard: 'rgba(240, 240, 240, 1)', // 卡片背景色
    colorHistoryDefault: '#83868E', // 顶部前进后退刷新默认字体颜色
    colorHistoryForbid: '#D6D7D9', // 顶部前进后退刷新禁用字体颜色
    colorBgFilter: '#E5EBF2', // 筛选背景色
    colorBorderMenu: '#E6E6E6', // 左侧树结构描边色
    colorPositionIcon: '#ADAFB4', // 定位图标
    colorModule: '#85571B', // 左侧树结构模块文本色
    colorBgAllMenuSelect: '#F0F0F0', // 菜单下拉展开背景色
    colorBgMenuSelect: '#E8E8E8', // 菜单选中背景色
    colorMenuSelect: token.colorPrimary, // 菜单选中描边
    colorScrollbar: '#D6D7D9', // 滚动条
    colorBgNavigatorSelected: '#FFFFFF', // 左侧导航选中背景色
    colorBgMouseHover: 'rgba(0,0,0, 0.04)', // 鼠标悬浮背景色
    colorBorderFilterComplete: token.colorPrimary, // 筛选后添加描边色
    colorTabBorder: '#E6E6E6', // 标签页描边色
    colorTabActive: token.colorPrimary, // 标签页激活态色
    colorTabWord: token.colorTextBase, // 标签页文字色
    colorBgTableTr: 'rgba(247, 247, 247, 1)', // 表格表头背景色
    colorIframeBody: '#fff', // iframe body 背景色
    colorBtnText: '#fff', // 自定义的 btn 文字颜色
    colorSearchBorder: 'rgb(230, 230, 230)', // 全局搜索框边框色
    colorListHover: 'rgb(245, 245, 245)', // 各种列表包括自定义组件的 hover 背景色
    colorFieldType: '#9F0D0D', // 字段类型颜色
    colorExpandFieldType: '#FF6A00', // 字段非基础类型颜色
    colorFieldName: '#0A3FAE', // 字段蓝色(用于字段名、方法参数名)
    colorFieldCname: calcTokens.colorTextTertiary, // 字段cname颜色
    colorBgTooltip: '#FAF7F0', // 表格、树tooltip背景色
    colorTooltipBorder: '#E6E6E6', // 表格、树tooltip边框色背景色
    colorBgAnchorHover: '#fff', // 卡片锚点悬浮色
    colorInputBottomBorder: '#ADAFB4', // 输入框、下拉框下方描边
    colorHistoryBody: '#FAF7F0', // 请求历史底色
    colorBgHover: 'rgba(0,0,0, 0.05)', // hover背景色
    colorBgTreeHover: 'rgba(0,0,0,0.05)', // @deprecated 树节点或者表格行hover背景色
    colorBgTreeActive: 'rgba(0,0,0,0.05)', // @deprecated 树节点或者表格行选中背景色
    borderTreeActive: `1px solid ${token?.colorPrimary}`, // @deprecated 树节点或者表格行选中边框色
    colorBgSearch: '#fff',
  };
};

const defaultTheme: ThemeConfig = {
  token: {
    wireframe: false,
    colorTextBase: '#313643',
    colorText: '#5a5e69',
    colorTextSecondary: '#83868e',
    colorTextTertiary: '#adafb4',
    colorTextQuaternary: '#d6d7d9',
    colorError: '#db433f',
    colorWarning: '#ff9500',
    borderRadius: 4,
    colorBorderSecondary: '#e6e6e6',
  },
  components: {
    Input: {
      borderRadius: 2,
      colorBorder: 'rgb(230, 230, 230)',
    },
    Select: {
      multipleItemBg: 'rgb(245, 245, 245)',
      multipleItemBorderColor: 'rgb(214, 215, 217)',
      optionSelectedBg: 'rgb(235, 241, 255)',
      optionSelectedColor: 'rgb(49, 54, 67)',
      colorBorder: 'rgb(230, 230, 230)',
    },
    Menu: {
      subMenuItemBg: 'rgb(240, 240, 240)',
    },
    Table: {
      borderColor: 'rgb(230, 230, 230)',
      headerBg: 'rgb(247, 247, 247)',
      headerColor: 'rgb(49, 54, 67)',
      headerSplitColor: 'rgb(230, 230, 230)',
      rowSelectedBg: '#EBF1FF',
      rowSelectedHoverBg: 'rgb(186, 207, 255)',
      cellPaddingBlockMD: 7,
      cellPaddingBlockSM: 5,
      cellPaddingBlock: 9,
    },
    Tag: {
      defaultBg: '#F2F2F2',
    },
  },
  algorithm: [customAlgorithm, compactAlgorithm],
};

export default defaultTheme;
