import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import createStoreObserver from 'redux-store-observe';
import commonReducer from './common';

const reducers = {
  common: commonReducer,
  //   project: projectReducer,
  //   page: pageRecuder,
} as {
  // electron里没有初始化的sub reducer所以类型上要处理成可能为undefeind
  //   project: Reducer<ProjectState | undefined>;
  //   page: Reducer<PageState | undefined>;
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });
  },
});

const storeObserver = createStoreObserver(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

(window as any).__STORE__ = store;
