/* eslint-disable react-hooks/exhaustive-deps */
import Styles from './index.module.css';

import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Image, message, Upload } from '@df/toco-ui';
import { useControllableValue } from 'ahooks';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { portalize } from '@/components/Portal';

import { download, getFileUrl, initFormData } from './utils';

const { Dragger } = Upload;

/**
 * 文件转base64
 * @param file
 * @returns
 */
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

/**
 * oss 上传参数
 */
export type OSSDataType = {
  dir: string;
  expire: string;
  host: string;
  accessKeyId?: string;
  OSSAccessKeyId?: string;
  policy: string;
  signature: string;
};

/**
 * oss 请求类型
 */
type OSSDataResType = {
  code: number;
  message?: string;
  data?: OSSDataType;
};

/**
 * oss promise
 */
type OSSDataTypeFunction = () => Promise<OSSDataType | null>;

type AliyunOSSUploadProps = {
  /**
   * 上传文件当前值
   */
  value?: UploadFile[];
  /**
   * 上传文件默认值
   */
  defaultValue?: UploadFile[];
  /**
   * oss 参数 | oss 参数接口
   */
  getUploadOssParams?: OSSDataType | OSSDataTypeFunction;
  /**
   * upload onchange 回调
   * @param fileListSimple 简版数据
   * @param fileList 全部数据
   * @returns void
   */
  onChange?: (fileList: UploadFile[]) => void;
  /**
   * 上传类型，暂定图片或者文件
   */
  uploadType?: 'picture' | 'file';
  /**
   * antd upload props
   */
  uploadParams?: UploadProps;
  /**
   * 上传按钮区域 props
   * icon 显示 icon
   * text 显示文本
   * node 自定义上传区域节点展示
   */
  uploadBlock?: {
    icon?: React.ReactNode;
    text?: string;
    node?: React.ReactNode;
  };
  /**
   * 是否支持 剪切板上传
   */
  uploadPaste?: boolean;
};

const AliyunOSSUpload = ({
  value: valueProp,
  defaultValue,
  onChange,
  uploadType = 'file',
  uploadParams = {},
  getUploadOssParams,
  uploadBlock = {
    icon: uploadType === 'picture' ? <PlusOutlined /> : <UploadOutlined />,
    text: '上传',
  },
  uploadPaste = false,
}: AliyunOSSUploadProps) => {
  /**
   * oss 上传参数
   */
  const [OSSData, setOSSData] = useState<OSSDataType>();
  /**
   * preview 相关
   */
  const [previewOpen, setPreviewOpen] = useState(false);

  const valueRef = useRef<UploadFile[]>();
  const portalRef = useRef<any>();

  /**
   * 受控非受控转换
   */
  const [value, setValue] = useControllableValue({
    value: valueProp,
    defaultValue: defaultValue ? defaultValue : undefined,
    onChange,
  });

  /**
   * 获取 oss 上传参数
   */
  const mockGetOSSData = async () => {
    const res =
      typeof getUploadOssParams === 'function'
        ? await getUploadOssParams()
        : getUploadOssParams;
    if (!res) return undefined;
    return {
      ...(res || {}),
      OSSAccessKeyId: res.OSSAccessKeyId || res.accessKeyId,
    };
  };

  /**
   * init
   * @returns
   */
  const init = async () => {
    try {
      const result = await mockGetOSSData();
      setOSSData(result);
      return result;
    } catch (error: any) {
      message.error(error);
    }
  };

  /**
   * 自定义上传
   * @param file 文件
   */
  const handleCustomUpload = async (file: UploadFile) => {
    const customOSSData = OSSData || (await init());
    if (customOSSData && file && file?.lastModified) {
      const ext = (file?.type || '').split('/').pop();
      const fileKey = `${customOSSData?.dir}/${file.lastModified}.${ext}`;
      const formData = initFormData({
        key: fileKey,
        OSSAccessKeyId: customOSSData?.OSSAccessKeyId,
        policy: customOSSData?.policy,
        Signature: customOSSData?.signature,
      });
      formData.append('file', file as RcFile);
      const res: any = await axios({
        url: customOSSData?.host,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      if (res?.status === 200 || res?.status === 204) {
        const target: UploadFile[] = cloneDeep(valueRef.current || []);
        target.push({
          name: `${file.lastModified}.${ext}`,
          url: `${customOSSData?.host}/${fileKey}`,
          uid: fileKey,
        });
        setValue?.(target);
        valueRef.current = target;
      }
    }
  };

  /**
   * 剪切板上传
   * @param event
   */
  const initPasteObserve = (event: Event) => {
    const items =
      (event as any).clipboardData && (event as any).clipboardData.items;
    let file: any = null;
    if (items && items.length) {
      // 检索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
    }
    handleCustomUpload(file);
  };

  /**
   * 剪贴板粘贴监听
   */
  useEffect(() => {
    init();
    if (uploadPaste) {
      document.addEventListener('paste', initPasteObserve);
    }

    return () => {
      if (uploadPaste) {
        document.removeEventListener('paste', initPasteObserve);
      }
    };
  }, []);

  /**
   * 文件上传变更
   * @param param fileList 文件列表
   */
  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    const target = [...fileList].map((item) => ({
      ...item,
      url: getFileUrl(item.url, OSSData?.host),
    }));
    setValue?.(target);
    valueRef.current = target;
  };

  const onRemove = (file: UploadFile) => {
    const files = ((value || []) as any[]).filter((v) => v.url !== file.url);

    if (onChange) {
      onChange(files);
    }
  };

  const getExtraData: UploadProps['data'] = (file) => ({
    key: file.url,
    OSSAccessKeyId: OSSData?.OSSAccessKeyId,
    policy: OSSData?.policy,
    Signature: OSSData?.signature,
  });

  const beforeUpload: UploadProps['beforeUpload'] = async (file: any) => {
    if (!OSSData) return false;

    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = `${OSSData.dir}/${filename}`;

    return file;
  };

  const handlePreviewClose = (visible: boolean) => {
    if (!visible) {
      portalRef?.current?.close();
    }
  };

  const handlePreview = async (file: UploadFile) => {
    const fileUrl = getFileUrl(file.url, OSSData?.host);
    if (uploadType === 'picture') {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }

      portalRef.current = portalize({
        component: (
          <Image
            className={Styles['preview-image']}
            width={200}
            src={fileUrl || (file.preview as string)}
            preview={{
              visible: true,
              onVisibleChange: handlePreviewClose,
            }}
          />
        ),
      });
    } else {
      download(fileUrl, file?.name || '');
    }
  };

  /**
   * 一般上传的props
   */
  const uploadProps: UploadProps = {
    name: 'file',
    fileList: value,
    action: OSSData?.host,
    onChange: handleChange,
    onPreview: handlePreview,
    onRemove,
    data: getExtraData,
    beforeUpload,
    listType: uploadType === 'picture' ? 'picture-card' : 'picture',
    className: uploadType === 'picture' ? '' : 'upload-list-inline',
    ...uploadParams,
  };

  const classNames = [Styles['toco-upload-container']];

  if (uploadType === 'file') {
    classNames.push(Styles['toco-upload-normal-container']);
  }

  return (
    <div className={classNames.join(' ')}>
      <Upload {...uploadProps}>
        {uploadParams?.maxCount &&
        value?.length >= uploadParams?.maxCount ? null : uploadType ===
          'picture' ? (
          <div>
            {uploadBlock?.icon}
            <div style={{ marginTop: 8 }}>{uploadBlock?.text}</div>
          </div>
        ) : (
          <Button style={{ width: 100 }} icon={uploadBlock?.icon}>
            {uploadBlock?.text}
          </Button>
        )}
      </Upload>
    </div>
  );
};

export default AliyunOSSUpload;
