import { message } from '@df/toco-ui';

type tocoMessageType = 'info' | 'success' | 'error' | 'warning' | 'loading';

export const tocoMessage = (data, type?: tocoMessageType) => {
  const text = data?.message;
  if (type) {
    message[type](text);
  } else {
    if (data?.code === 200) {
      text && message.success(text);
    } else if (data?.code === 500) {
      // text && message.error(text);
    } else {
      text && message.error(text);
    }
  }
};

const Index = () => {
  return <div></div>;
};

export default Index;
