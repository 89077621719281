import { theme, ThemeConfig } from '@df/toco-ui';
import { SeedToken } from 'antd/es/theme/interface/seeds';
import { MapToken } from './index';

const { compactAlgorithm, darkAlgorithm } = theme;

function hexToRgb(hex) {
  // 移除可能存在的'#'符号，并确保是6位长度
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (h) {
        return h + h;
      })
      .join('');
  }

  // 将十六进制转换为RGB值
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

const customAlgorithm: (token: SeedToken) => MapToken = (token) => {
  const calcTokens = darkAlgorithm(token);

  const controlDefaultHeight = 32;

  return {
    ...calcTokens,
    controlDefaultHeight,
    controlHeight: controlDefaultHeight,
    colorBgEdit: '#282C34', // 编辑页面底色
    colorPrimaryValue: hexToRgb(token.colorPrimary),
    colorPrimary: token.colorPrimary,
    colorBorderTertiary: '#3F4451',
    colorBorderCustom: '#23252C', // 边框颜色自定义
    colorBgSelected: '#3C424D', // 选中背景色
    colorBgFloatBar: '#3D4148', // 悬浮工具栏背景色
    colorBgCard: '#21252B', // 卡片背景色
    colorHistoryDefault: '#ADAFB4', // 顶部前进后退刷新默认字体颜色
    colorHistoryForbid: '#5A5E69', // 顶部前进后退刷新禁用字体颜色
    colorBgFilter: '#3C424D', // 筛选背景色
    colorBorderMenu: '#282C34', // 左侧树结构描边色
    colorPositionIcon: '#83868E', // 定位图标
    colorModule: '#DFC193', // 左侧树结构模块文本色
    colorBgAllMenuSelect: '#2D3139', // 菜单下拉展开背景色
    colorBgMenuSelect: '#3C424D', // 菜单选中背景色
    colorMenuSelect: '#83868E', // 菜单选中描边
    colorScrollbar: '#5A5E69', // 滚动条
    colorBgNavigatorSelected: '#3C424D', // 左侧导航选中背景色
    colorBgMouseHover: 'rgba(255,255,255, 0.04)', // 鼠标悬浮背景色
    colorBorderFilterComplete: '#737373', // 筛选后添加描边色
    colorTabBorder: 'transparent', // 标签页描边色
    colorTabActive: '#fff', // 标签页激活态色
    colorTabWord: '#DFC193', // 标签页文字色
    colorBgTableTr: 'rgba(33, 37, 43, 1)', // 表格表头背景色
    colorIframeBody: '#282c33', // iframe body 背景色
    colorBtnText: '#313643', // 自定义的 btn 文字颜色
    colorSearchBorder: 'rgb(63, 68, 81)', // 全局搜索框边框色
    colorListHover: 'rgba(36, 41, 51)', // 各种列表包括自定义组件的 hover 背景色
    colorFieldType: '#AB3A3D', // 字段类型颜色
    colorExpandFieldType: '#D77477', // 字段非基础类型颜色
    colorFieldName: '#6BAFEA', // 字段蓝色(用于字段名、方法参数名)
    colorFieldCname: calcTokens.colorTextTertiary, // 字段cname颜色
    colorBgTooltip: '#45474D', // 表格、树tooltip背景色
    colorTooltipBorder: '#3F4451', // 表格、树tooltip边框色背景色
    colorBgAnchorHover: 'rgba(47, 54, 64, 1)', // 卡片锚点悬浮色
    colorInputBottomBorder: '#6F7482', // 输入框、下拉框下方描边
    colorHistoryBody: '#21252B', // 请求历史底色
    colorBgHover: 'rgba(255,255,255, 0.05)', // hover背景色
    colorBgTreeHover: 'rgba(255,255,255,0.05)', // @deprecated 树节点或者表格行hover背景色
    colorBgTreeActive: 'rgba(255,255,255,0.05)', // @deprecated 树节点或者表格行选中背景色
    borderTreeActive: `1px solid ${token?.colorPrimary}`, // @deprecated 树节点或者表格行选中边框色
    colorBgSearch: 'rgba(47, 54, 64, 1)',
  };
};

const darkTheme: ThemeConfig = {
  token: {
    colorBgBase: '#282C34', // 页面底色
    colorBgElevated: '#2F3640', // 浮层容器背景色
    colorTextBase: '#EFF0F2',
    borderRadiusLG: 4,
    borderRadius: 4,
    wireframe: false,
    colorBgContainer: '#2d3139',
    colorText: '#d6d7d9cc',
    colorTextSecondary: '#d6d7d9',
    colorTextTertiary: '#d6d7d966',
    colorTextQuaternary: '#d6d7d933',
    colorBorderSecondary: '#3f4451',
    colorBorder: '#83868e',
  },
  components: {
    Menu: {
      itemSelectedBg: 'rgb(60, 66, 77)',
      subMenuItemBg: 'rgb(45, 49, 57)',
      colorSplit: 'rgb(35, 37, 44)',
    },
    Table: {
      rowSelectedBg: '#3C424D',
    },
    Tag: {
      defaultBg: '#4E5562',
    },
  },
  algorithm: [customAlgorithm, compactAlgorithm],
};

export default darkTheme;
