import classNames from 'classnames';
import './index.css';

export type IconProps = {
  type: string;
  style?: React.CSSProperties;
  size?: number;
  className?: string;
  tabIndex?: number;
  title?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLSpanElement>) => void;
};

const Icon = (props: IconProps) => {
  const {
    type,
    style = {},
    size = 14,
    className = '',
    onClick,
    onMouseUp,
    ...rest
  } = props;

  return (
    <span
      className={classNames('toco-icon-container', className, {
        clickable: !!onClick,
      })}
      onClick={onClick}
      onMouseUp={onMouseUp}
      {...rest}
    >
      <svg
        className={classNames('toco-icon', 'icon svg-icon')}
        aria-hidden="true"
        style={{
          fontSize: size,
          ...style,
        }}
      >
        <use xlinkHref={`#${type}`}></use>
      </svg>
    </span>
  );
};

export default Icon;
