const getBlob = (
  url: string | URL,
  cb: { (blob: any): void; (arg0: any): void },
) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
};

const saveAs = (blob: Blob | MediaSource, filename: string) => {
  const _window: any = window;
  if (_window.navigator.msSaveOrOpenBlob) {
    (navigator as any)?.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
};

const checkIfUrl = (str: string) => {
  const regex = /^(http|https):\/\//;
  return regex.test(str);
};

export const getFileUrl = (url: string | undefined, suffix = '') => {
  if (url && checkIfUrl(url)) {
    return url;
  } else {
    return `${suffix}/${url}`;
  }
};

const splitString = (str: string, sign = ',') => {
  const parts = str.split(sign);
  const lastPart = parts.pop();
  const firstPart = parts.join(sign);
  return [firstPart, lastPart];
};

export const getFileName = (file: { name: any }, fileList: any[]) => {
  let i = 0;
  const recursion: any = (v: string) => {
    const target = fileList?.find((item: { name: any }) => item?.name === v);
    i += 1;
    if (!target) {
      return v;
    } else {
      // eslint-disable-next-line no-useless-escape
      const result = splitString(v, '.');
      const newName = `${result?.[0]}（${i}）.${result?.[1]}`;
      return recursion(newName);
    }
  };
  return recursion(file?.name || '');
};

/**
 * 保存文件
 * @param url 目标文件地址
 * @param filename 想要保存的文件名称
 */
export const download = (url: any, filename: string) => {
  getBlob(url, function (blob: any) {
    saveAs(blob, filename);
  });
};

export const initFormData = (object: any) => {
  const formData = new FormData();
  const target = Object.keys(object || {});
  target?.forEach((key) => {
    if (object[key]) {
      formData.append(key, object[key]);
    }
  });
  return formData;
};
