import { render, unmount } from 'rc-util/lib/React/render';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

declare global {
  interface Window {
    __VP_PORTAL_EVENT__?: {
      [key: string]: DocumentFragment;
    };
  }
}

type VSPortalPropsType = {
  /**
   * 影响 portal 插入位置的event,
   */
  event?:
    | MouseEvent
    | {
        clientX?: number | string;
        clientY?: number | string;
      };
  /**
   * destoryClickOutside callback
   */
  onClose?: () => void;
  /**
   * portal contains component
   */
  component?: ReactNode;
  /**
   * 是否开启不在portal component内点击就close
   */
  destoryClickOutside?: boolean;
  /**
   * event key
   */
  key?: string;
  /**
   * style
   */
  style?: any;
};

const Index = (props: VSPortalPropsType) => {
  const { event, onClose, component, destoryClickOutside, style = {} } = props;
  const domNode = document.body;
  const wrapperRef = useRef<any>();
  const handleClickOutside = useCallback(
    (e: any) => {
      if (!wrapperRef?.current?.contains(e.target)) {
        onClose?.();
      }
    },
    [onClose],
  );
  useEffect(() => {
    if (destoryClickOutside) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (destoryClickOutside) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [handleClickOutside, destoryClickOutside]);
  return ReactDOM.createPortal(
    <div
      ref={wrapperRef}
      style={{
        position: 'absolute',
        top: event?.clientY || 0,
        left: event?.clientX || 0,
        ...style,
      }}
      // onClick={onClose}
    >
      {component}
    </div>,
    domNode,
  );
};

export const portalize = ({
  component,
  destoryClickOutside = false,
  event,
  key = 'VSF',
  style = {},
}: VSPortalPropsType) => {
  const container: any = document.createDocumentFragment();
  let timeoutId: any;

  // dom handle
  if (window.__VP_PORTAL_EVENT__) {
    unmount(window.__VP_PORTAL_EVENT__[key]);
  }

  function renderModal() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      render(
        <Index
          destoryClickOutside={destoryClickOutside}
          event={event}
          onClose={() => {
            unmount(container);
          }}
          component={component}
          style={style}
        />,
        container,
      );
      window.__VP_PORTAL_EVENT__ = {
        [key]: container,
      };
    });
  }

  renderModal();
  container.close = () => {
    unmount(container);
  };
  return container;
};

export default Index;
