import { message } from '@df/toco-ui';
import service from '@vs/service';

const oauthUrl = (url?: string) => {
  return (
    !!url && (url.indexOf('openid-connect') >= 0 || url.indexOf('oauth2') >= 0)
  );
};
// FIXME: 不应该做统一拦截，应该业务各自处理
service.defaults.withCredentials = true;
service.interceptors.response.use((res) => {
  if (res?.data?.code >= 300 && res?.data?.code < 400) {
    // FIXME: 跳转到登录
    message.error('用户未登录');
  }
  return res;
});

service.interceptors.request.use(
  (config) => {
    // const { url } = config;
    // const ids = store.getState().studio.layout?.ids;
    // let projectId = store.getState().persist.project?.uuid;
    // if (!projectId) {
    //   projectId = ids?.projectId;
    // }
    // if (projectId) {
    //   if (url) {
    //     config.withCredentials = true;
    //     if (!oauthUrl(url)) {
    //       config.headers['projectId'] =
    //         config.headers['projectId'] ?? projectId;
    //       if (ids?.moduleId) {
    //         config.headers['moduleId'] =
    //           config.headers['moduleId'] ?? ids?.moduleId;
    //       }
    //     }
    //   }
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
