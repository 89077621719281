import { Spin } from '@df/toco-ui';
import React, { Suspense } from 'react';
import styles from './index.module.css';

type SuspenseLayoutProps = {
  children: React.ReactElement;
};
const SuspenseLayout = (props: SuspenseLayoutProps) => {
  const { children } = props;
  return (
    <Suspense
      fallback={
        <div className={styles.loader}>
          <Spin></Spin>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export default SuspenseLayout;
