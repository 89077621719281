import { Modal } from '@df/toco-ui';

import { ModalProps } from '@df/toco-ui';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.css';

type modalFormPropsType = {
  open?: boolean;
  triggerNode?: any;
  children?: React.ReactNode;
  formHandle?: any;
  onSubmit?: (v) => Promise<boolean>;
} & ModalProps;

const Index = (props: modalFormPropsType, ref: any) => {
  const {
    open: openProps,
    triggerNode,
    children,
    formHandle,
    onSubmit,
    ...rest
  } = props;

  const [open, setOpen] = useState<boolean>(openProps ?? false);
  const dom = triggerNode
    ? React.cloneElement(triggerNode, {
        onClick: () => {
          setOpen(true);
          triggerNode?.props?.onClick?.();
        },
      })
    : triggerNode;

  const handleOk = async () => {
    try {
      const values = await formHandle?.current?.validateFields();
      if (!values?.errorFields) {
        const res = onSubmit?.(values);
        res && setOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (typeof openProps !== undefined) {
      setOpen(openProps ?? false);
    }
  }, [openProps]);

  useImperativeHandle(ref, () => ({
    setOpen: (v) => {
      setOpen(v);
    },
  }));

  return (
    <div className={styles?.['toco-manage-modal-form']}>
      {dom}
      <Modal
        open={open}
        closable={false}
        {...rest}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        {children}
      </Modal>
    </div>
  );
};

export default React.forwardRef(Index);
